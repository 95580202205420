.content {
  border: 1px solid var(--border);
  @include m-space("bottom", 4);
  h1 {
    @include m-space("bottom", 4);
  }

  & > * + * {
    @include m-space("top", 2);
  }
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}
