@use "sass:map";

input,
select {
  border: 1px solid var(--validity-color, #{map.get($primaries, 70)});
  border-radius: 2px;
  box-shadow: var(--validity-shadow, none);
  &:invalid {
    --validity-color: rgba(240, 0, 0, 0.8);
    --validity-shadow: 0 0 5px 1px var(--validity-color);
  }
}

input[type="text"] {
  &:disabled,
  &.disabled {
    background-color: map.get($grays, 30);
    color: map.get($grays, 10);
  }
}

.input-group {
  display: flex;
  flex-direction: column;
}

.text-input {
  align-items: center;
  display: flex;
  flex-basis: 100%;
  flex-direction: row;
  justify-content: space-between;
}

#randomText {
  flex-grow: 0;
  margin: 0 auto;
}

.form-group {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  @include m-space("bottom", 2);
  @include mq($from: "md") {
    flex-direction: row;
  }

  label {
    flex-grow: 0;
    @include m-space("bottom", 1);
    @include mq($from: "md") {
      margin-bottom: 0;
    }
  }
  & > input,
  & > .input-group {
    flex-basis: 100%;
    flex-grow: 0;
    @include mq($from: "md") {
      flex-basis: 50%;
      flex-wrap: wrap;
    }
  }
}

.help-text {
  color: #000000;
  font-weight: 200;
}
