@use "sass:math";
@use "sass:list";
@use "sass:string";

@import "../../../node_modules/rfs/scss.scss";

@mixin font-scale($scale) {
  @include font-size(scale-value($scale));
}

@mixin prop-short($prop, $suffix, $values) {
  $top: $prop + "-top" + if($suffix, "-#{suffix}", "");
  $bottom: $prop + "-bottom" + if($suffix, "-#{suffix}", "");
  $left: $prop + "-left" + if($suffix, "-#{suffix}", "");
  $right: $prop + "-right" + if($suffix, "-#{suffix}", "");
  $all: $prop + if($suffix, "-#{suffix}", "");

  $values: comp-css-shorthand($values);
  $n: list.length($values);
  @if list-all($values) {
    #{$all}: $values;
  } @else {
    @if nth($values, 1) {
      #{$top}: list.nth($values, 1);
    }
    @if $n == 1 {
      @if nth($values, 1) {
        #{$right}: list.nth($values, 1);
      }
    } @else {
      @if nth($values, 2) {
        #{$right}: list.nth($values, 2);
      }
    }

    @if $n == 2 {
      @if list.nth($values, 1) {
        #{$bottom}: list.nth($values, 1);
      }
      @if list.nth($values, 2) {
        #{$left}: list.nth($values, 2);
      }
    } @else if $n == 3 {
      @if list.nth($values, 3) {
        #{$bottom}: list.nth($values, 3);
      }
      @if list.nth($values, 2) {
        #{$left}: list.nth($values, 2);
      }
    } @else if $n == 4 {
      @if list.nth($values, 3) {
        #{$bottom}: list.nth($values, 3);
      }
      @if nth($values, 4) {
        #{$left}: list.nth($values, 4);
      }
    }
  }
}

@mixin prop-dir($property, $direction, $value) {
  $direction: validate-direction($direction);
  $property: string.unquote($property);
  @if $direction == "x" {
    #{$property}-left: $value;
    #{$property}-right: $value;
  } @else if $direction == "y" {
    #{$property}-top: $value;
    #{$property}-bottom: $value;
  } @else if list.index(left right top bottom, $direction) {
    #{$property}-#{$direction}: $value;
  } @else if not $direction {
    #{$property}: $value;
  } @else {
    @warn "Expected one of #{$directions}, got #{$direction}";
  }
}

@mixin sz($width, $height: $width) {
  height: $height;
  width: $width;
}

@mixin m-space($dir, $i) {
  @include prop-dir(margin, $dir, space-value($i));
}

@mixin m-short($values) {
  @include prop-short(margin, null, short-spaces($values));
}

@mixin p-short($values) {
  @include prop-short(padding, null, short-spaces($values));
}

@mixin p-space($dir, $i) {
  @include prop-dir(padding, $dir, space-value($i));
}
