.wrapper {
  display: grid;
  grid-template-columns: 1fr min(100ch, 100%) 1fr;

  @include m-short(3 2);
}

.wrapper > * {
  grid-column: 2;
}

body {
  font-family: $font-family-base;
}

a[href] {
  color: var(--primary);
}

pre,
code,
kbd,
samp {
  font-family: $font-family-mono;
}

img {
  height: auto;
  max-width: 100%;
  @include m-space("top", 2);
  @include m-space("bottom", 4);
}

.rule {
  display: block;
}
