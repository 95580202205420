footer {
  border: 1px solid var(--border);
  text-align: center;
  width: 100%;
  @include font-scale(-1);
  @include p-short(3);

  & > * + * {
    @include m-space("top", 3);
  }

  h6 {
    @include font-scale(0);
    @include m-short(2);
    &:last-child {
      margin-top: 0;
    }
  }
}
