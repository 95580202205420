:root {
  --ratio: #{$modular-scale-ratio};
  --scale0: #{$modular-scale-base};

  @for $i from -1 through $font-scale-min {
    --scale#{$i}: #{modular-scale($i)};
  }
  @for $i from 1 through $font-scale-max {
    --scale#{$i}: #{modular-scale($i)};
  }

  @each $name, $color in $colors {
    --#{$name}: #{$color};
  }

  --ring-color: currentcolor;
  --ring-inset: ;
  --ring-offset-width: 0px;
}
