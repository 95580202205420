@use "sass:map";

$h-spacing: 5rem;

#params-form {
  border: 1px solid var(--border);
  @include p-short(3);
  @include m-space("x", 2);
  @include mq($from: "md") {
    @include m-space("x", 7);
  }
}

#routes,
.endpoint-container {
  @include p-space("x", 2);
  @include mq($from: "md") {
    @include p-space("x", 7);
  }
}

#routes {
  @include p-space("bottom", 3);
  h3 {
    margin-block-end: 0;
    @include m-space("bottom", 2);
  }
  p {
    margin-block-start: 0;
  }
}

#endpoint {
  @include m-space("bottom", 2);
  @include mq($from: "md") {
    margin-bottom: 0;
  }
  & > a {
    color: inherit;
  }
  &:hover {
    text-decoration: underline;
  }
}

.endpoint-container {
  flex-direction: column;
  @include mq($from: "md") {
    flex-direction: row;
  }
}

.text-row {
  @include p-space("x", 2);
  @include mq($from: "md") {
    @include p-space("x", 0);
  }
}

#example-image {
  height: auto;
  max-height: 100vh;
  max-width: 100%;
  @include mq($from: "md") {
    max-height: 50vh;
  }
}
