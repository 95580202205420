@use "sass:math";

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
}

@for $i from 1 through 6 {
  h#{$i} {
    @include font-scale(math.max(-$i + 6, 1));
  }
}
