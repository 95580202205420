/* purgecss start ignore */
.feather {
  align-self: center;
  bottom: 1px;
  height: var(--feather-size, 1em);
  margin-left: 0.1em;
  position: relative;
  stroke: var(--feather-stroke, hsl(0, 0%, 0%));
  vertical-align: middle;
  width: var(--feather-size, 1em);
}

$scale-max: 1.06;

@keyframes copied {
  from {
    background-position: bottom right;
    transform: scale3d(1, 1, 1);
  }
  50% {
    transform: scale3d(#{$scale-max}, #{$scale-max}, #{$scale-max});
  }
  to {
    background-position: bottom left;
    transform: scale3d(1, 1, 1);
  }
}

#copy-button {
  --ring-color: hsl(210, 100%, 75%);
  --gradient-from-color: rgba(44, 82, 130, 0.9);
  --gradient-to-color: rgba(0, 100, 255, 0.3);
  --gradient-color-stops: var(--gradient-from-color) 50%,
    var(--gradient-to-color) 100%;
  --feather-stroke: #ffffff;
  align-items: center;
  animation-direction: normal;
  animation-duration: 1250ms;
  animation-fill-mode: backwards;
  animation-timing-function: ease-in-out;
  background-clip: padding-box;
  background-image: linear-gradient(to right, var(--gradient-color-stops));
  background-position: left bottom;
  background-repeat: no-repeat;
  background-size: 200% 100%;
  border-radius: 5px;
  color: var(--feather-stroke);
  cursor: pointer;
  display: flex;
  justify-content: space-around;
  padding: 0.33rem 0.5rem;
  &:disabled {
    --feather-stroke: hsl(0, 0%, 73%);
    cursor: default;
  }
  &:not(:disabled):hover {
    --feather-stroke: hsl(0, 0%, 100%);
    --gradient-from-color: hsl(210, 100%, 56%);
    box-shadow: var(--ring-inset) 0 0 0 calc(2px + var(--ring-offset-width))
      var(--ring-color);
    color: var(--feather-stroke);
  }
  &::after {
    content: "Copy to Clipboard";
    margin-left: modular-scale(-2);
  }
  & .feather-check {
    display: none;
  }
  & .feather-copy {
    display: block;
  }
  &.just-copied {
    animation-name: copied;

    &::after {
      content: "Copied!";
    }
    & .feather-check {
      display: block;
    }
    & .feather-copy {
      display: none;
    }
  }
}
/* purgecss end ignore */
