@import "sanitize.css/sanitize.css";
@import "sanitize.css/forms.css";
.wrapper {
  display: grid;
  grid-template-columns: 1fr min(100ch, 100%) 1fr;
  margin: 0.8rem 0.512rem;
}

.wrapper > * {
  grid-column: 2;
}

body {
  font-family: "Spectral", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

a[href] {
  color: var(--primary);
}

pre,
code,
kbd,
samp {
  font-family: "Fira Mono", SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

img {
  height: auto;
  max-width: 100%;
  margin-top: 0.512rem;
  margin-bottom: 1.25rem;
}

.rule {
  display: block;
}

:root {
  --ratio: 1.25;
  --scale0: 0.8rem;
  --scale-1: 0.64rem;
  --scale-2: 0.512rem;
  --scale-3: 0.4096rem;
  --scale1: 1rem;
  --scale2: 1.25rem;
  --scale3: 1.5625rem;
  --primary: #1275d9;
  --border: #d3d3ca;
  --ring-color: currentcolor;
  --ring-inset: ;
  --ring-offset-width: 0px;
}

.d-none {
  display: none !important;
}

.invisible {
  visibility: hidden !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.32768rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.32768rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.32768rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.32768rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.32768rem !important;
}

.m-2 {
  margin: 0.512rem !important;
}

.mt-2,
.my-2 {
  margin-top: 0.512rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 0.512rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 0.512rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 0.512rem !important;
}

.m-3 {
  margin: 0.8rem !important;
}

.mt-3,
.my-3 {
  margin-top: 0.8rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 0.8rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 0.8rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 0.8rem !important;
}

.m-4 {
  margin: 1.25rem !important;
}

.mt-4,
.my-4 {
  margin-top: 1.25rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 1.25rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 1.25rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 1.25rem !important;
}

.m-5 {
  margin: 1.953125rem !important;
}

.mt-5,
.my-5 {
  margin-top: 1.953125rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 1.953125rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 1.953125rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 1.953125rem !important;
}

.m-6 {
  margin: 3.0517578125rem !important;
}

.mt-6,
.my-6 {
  margin-top: 3.0517578125rem !important;
}

.mr-6,
.mx-6 {
  margin-right: 3.0517578125rem !important;
}

.mb-6,
.my-6 {
  margin-bottom: 3.0517578125rem !important;
}

.ml-6,
.mx-6 {
  margin-left: 3.0517578125rem !important;
}

.m-7 {
  margin: 4.768371582rem !important;
}

.mt-7,
.my-7 {
  margin-top: 4.768371582rem !important;
}

.mr-7,
.mx-7 {
  margin-right: 4.768371582rem !important;
}

.mb-7,
.my-7 {
  margin-bottom: 4.768371582rem !important;
}

.ml-7,
.mx-7 {
  margin-left: 4.768371582rem !important;
}

.m-8 {
  margin: 7.4505805969rem !important;
}

.mt-8,
.my-8 {
  margin-top: 7.4505805969rem !important;
}

.mr-8,
.mx-8 {
  margin-right: 7.4505805969rem !important;
}

.mb-8,
.my-8 {
  margin-bottom: 7.4505805969rem !important;
}

.ml-8,
.mx-8 {
  margin-left: 7.4505805969rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.32768rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.32768rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.32768rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.32768rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.32768rem !important;
}

.p-2 {
  padding: 0.512rem !important;
}

.pt-2,
.py-2 {
  padding-top: 0.512rem !important;
}

.pr-2,
.px-2 {
  padding-right: 0.512rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 0.512rem !important;
}

.pl-2,
.px-2 {
  padding-left: 0.512rem !important;
}

.p-3 {
  padding: 0.8rem !important;
}

.pt-3,
.py-3 {
  padding-top: 0.8rem !important;
}

.pr-3,
.px-3 {
  padding-right: 0.8rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 0.8rem !important;
}

.pl-3,
.px-3 {
  padding-left: 0.8rem !important;
}

.p-4 {
  padding: 1.25rem !important;
}

.pt-4,
.py-4 {
  padding-top: 1.25rem !important;
}

.pr-4,
.px-4 {
  padding-right: 1.25rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 1.25rem !important;
}

.pl-4,
.px-4 {
  padding-left: 1.25rem !important;
}

.p-5 {
  padding: 1.953125rem !important;
}

.pt-5,
.py-5 {
  padding-top: 1.953125rem !important;
}

.pr-5,
.px-5 {
  padding-right: 1.953125rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 1.953125rem !important;
}

.pl-5,
.px-5 {
  padding-left: 1.953125rem !important;
}

.p-6 {
  padding: 3.0517578125rem !important;
}

.pt-6,
.py-6 {
  padding-top: 3.0517578125rem !important;
}

.pr-6,
.px-6 {
  padding-right: 3.0517578125rem !important;
}

.pb-6,
.py-6 {
  padding-bottom: 3.0517578125rem !important;
}

.pl-6,
.px-6 {
  padding-left: 3.0517578125rem !important;
}

.p-7 {
  padding: 4.768371582rem !important;
}

.pt-7,
.py-7 {
  padding-top: 4.768371582rem !important;
}

.pr-7,
.px-7 {
  padding-right: 4.768371582rem !important;
}

.pb-7,
.py-7 {
  padding-bottom: 4.768371582rem !important;
}

.pl-7,
.px-7 {
  padding-left: 4.768371582rem !important;
}

.p-8 {
  padding: 7.4505805969rem !important;
}

.pt-8,
.py-8 {
  padding-top: 7.4505805969rem !important;
}

.pr-8,
.px-8 {
  padding-right: 7.4505805969rem !important;
}

.pb-8,
.py-8 {
  padding-bottom: 7.4505805969rem !important;
}

.pl-8,
.px-8 {
  padding-left: 7.4505805969rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto;
}

.mr-auto,
.mx-auto {
  margin-right: auto;
}

.mb-auto,
.my-auto {
  margin-bottom: auto;
}

.ml-auto,
.mx-auto {
  margin-left: auto;
}

/* purgecss start ignore */
.feather {
  align-self: center;
  bottom: 1px;
  height: var(--feather-size, 1em);
  margin-left: 0.1em;
  position: relative;
  stroke: var(--feather-stroke, black);
  vertical-align: middle;
  width: var(--feather-size, 1em);
}

@keyframes copied {
  from {
    background-position: bottom right;
    transform: scale3d(1, 1, 1);
  }
  50% {
    transform: scale3d(1.06, 1.06, 1.06);
  }
  to {
    background-position: bottom left;
    transform: scale3d(1, 1, 1);
  }
}
#copy-button {
  --ring-color: hsl(210, 100%, 75%);
  --gradient-from-color: rgba(44, 82, 130, 0.9);
  --gradient-to-color: rgba(0, 100, 255, 0.3);
  --gradient-color-stops: var(--gradient-from-color) 50%,
    var(--gradient-to-color) 100%;
  --feather-stroke: #ffffff;
  align-items: center;
  animation-direction: normal;
  animation-duration: 1250ms;
  animation-fill-mode: backwards;
  animation-timing-function: ease-in-out;
  background-clip: padding-box;
  background-image: linear-gradient(to right, var(--gradient-color-stops));
  background-position: left bottom;
  background-repeat: no-repeat;
  background-size: 200% 100%;
  border-radius: 5px;
  color: var(--feather-stroke);
  cursor: pointer;
  display: flex;
  justify-content: space-around;
  padding: 0.33rem 0.5rem;
}
#copy-button:disabled {
  --feather-stroke: hsl(0, 0%, 73%);
  cursor: default;
}
#copy-button:not(:disabled):hover {
  --feather-stroke: hsl(0, 0%, 100%);
  --gradient-from-color: hsl(210, 100%, 56%);
  box-shadow: var(--ring-inset) 0 0 0 calc(2px + var(--ring-offset-width)) var(--ring-color);
  color: var(--feather-stroke);
}
#copy-button::after {
  content: "Copy to Clipboard";
  margin-left: 0.512rem;
}
#copy-button .feather-check {
  display: none;
}
#copy-button .feather-copy {
  display: block;
}
#copy-button.just-copied {
  animation-name: copied;
}
#copy-button.just-copied::after {
  content: "Copied!";
}
#copy-button.just-copied .feather-check {
  display: block;
}
#copy-button.just-copied .feather-copy {
  display: none;
}

/* purgecss end ignore */
.align-items-center {
  align-items: center !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.content {
  border: 1px solid var(--border);
  margin-bottom: 1.25rem;
}
.content h1 {
  margin-bottom: 1.25rem;
}
.content > * + * {
  margin-top: 0.512rem;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}

footer {
  border: 1px solid var(--border);
  text-align: center;
  width: 100%;
  font-size: 0.64rem;
  padding: 0.8rem;
}
footer > * + * {
  margin-top: 0.8rem;
}
footer h6 {
  font-size: calc(0.7090909091rem + 0.1212121212vw);
  margin: 0.512rem;
}
@media (min-width: 1200px) {
  footer h6 {
    font-size: 0.8rem;
  }
}
footer h6:last-child {
  margin-top: 0;
}

input,
select {
  border: 1px solid var(--validity-color, #00317a);
  border-radius: 2px;
  box-shadow: var(--validity-shadow, none);
}
input:invalid,
select:invalid {
  --validity-color: rgba(240, 0, 0, 0.8);
  --validity-shadow: 0 0 5px 1px var(--validity-color);
}

input[type=text]:disabled, input[type=text].disabled {
  background-color: #c3c3b6;
  color: #edede9;
}

.input-group {
  display: flex;
  flex-direction: column;
}

.text-input {
  align-items: center;
  display: flex;
  flex-basis: 100%;
  flex-direction: row;
  justify-content: space-between;
}

#randomText {
  flex-grow: 0;
  margin: 0 auto;
}

.form-group {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 0.512rem;
}
@media (min-width: 48em) {
  .form-group {
    flex-direction: row;
  }
}
.form-group label {
  flex-grow: 0;
  margin-bottom: 0.32768rem;
}
@media (min-width: 48em) {
  .form-group label {
    margin-bottom: 0;
  }
}
.form-group > input, .form-group > .input-group {
  flex-basis: 100%;
  flex-grow: 0;
}
@media (min-width: 48em) {
  .form-group > input, .form-group > .input-group {
    flex-basis: 50%;
    flex-wrap: wrap;
  }
}

.help-text {
  color: #000000;
  font-weight: 200;
}

#params-form {
  border: 1px solid var(--border);
  padding: 0.8rem;
  margin-left: 0.512rem;
  margin-right: 0.512rem;
}
@media (min-width: 48em) {
  #params-form {
    margin-left: 4.768371582rem;
    margin-right: 4.768371582rem;
  }
}

#routes,
.endpoint-container {
  padding-left: 0.512rem;
  padding-right: 0.512rem;
}
@media (min-width: 48em) {
  #routes,
.endpoint-container {
    padding-left: 4.768371582rem;
    padding-right: 4.768371582rem;
  }
}

#routes {
  padding-bottom: 0.8rem;
}
#routes h3 {
  margin-block-end: 0;
  margin-bottom: 0.512rem;
}
#routes p {
  margin-block-start: 0;
}

#endpoint {
  margin-bottom: 0.512rem;
}
@media (min-width: 48em) {
  #endpoint {
    margin-bottom: 0;
  }
}
#endpoint > a {
  color: inherit;
}
#endpoint:hover {
  text-decoration: underline;
}

.endpoint-container {
  flex-direction: column;
}
@media (min-width: 48em) {
  .endpoint-container {
    flex-direction: row;
  }
}

.text-row {
  padding-left: 0.512rem;
  padding-right: 0.512rem;
}
@media (min-width: 48em) {
  .text-row {
    padding-left: 0;
    padding-right: 0;
  }
}

#example-image {
  height: auto;
  max-height: 100vh;
  max-width: 100%;
}
@media (min-width: 48em) {
  #example-image {
    max-height: 50vh;
  }
}

@font-face {
  font-family: "Spectral";
  src: url("./theme/fonts/spectral-v6-latin-regular.woff") format("woff"), url("./theme/fonts/spectral-v6-latin-regular.woff2") format("woff2");
  font-display: block;
  font-style: normal;
  font-weight: 400;
}
@font-face {
  font-family: "Spectral";
  src: url("./theme/fonts/spectral-v6-latin-700.woff") format("woff"), url("./theme/fonts/spectral-v6-latin-700.woff2") format("woff2");
  font-display: block;
  font-style: normal;
  font-weight: 700;
}
@font-face {
  font-family: "Spectral";
  src: url("./theme/fonts/spectral-v6-latin-200.woff") format("woff"), url("./theme/fonts/spectral-v6-latin-200.woff2") format("woff2");
  font-display: block;
  font-style: normal;
  font-weight: 200;
}
@font-face {
  font-family: "Spectral";
  src: url("./theme/fonts/spectral-v6-latin-italic.woff") format("woff"), url("./theme/fonts/spectral-v6-latin-italic.woff2") format("woff2");
  font-display: block;
  font-style: italic;
  font-weight: 400;
}
@font-face {
  font-family: "Fira Mono";
  src: url("./theme/fonts/fira-mono-v8-latin-regular.woff") format("woff"), url("./theme/fonts/fira-mono-v8-latin-regular.woff2") format("woff2");
  font-display: block;
  font-style: normal;
  font-weight: 400;
}
p {
  margin: 0.512rem 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
}

h1 {
  font-size: calc(0.8583096591rem + 2.1107954545vw);
}
@media (min-width: 1200px) {
  h1 {
    font-size: 2.44140625rem;
  }
}

h2 {
  font-size: calc(0.8139204545rem + 1.5189393939vw);
}
@media (min-width: 1200px) {
  h2 {
    font-size: 1.953125rem;
  }
}

h3 {
  font-size: calc(0.7784090909rem + 1.0454545455vw);
}
@media (min-width: 1200px) {
  h3 {
    font-size: 1.5625rem;
  }
}

h4 {
  font-size: calc(0.75rem + 0.6666666667vw);
}
@media (min-width: 1200px) {
  h4 {
    font-size: 1.25rem;
  }
}

h5 {
  font-size: calc(0.7272727273rem + 0.3636363636vw);
}
@media (min-width: 1200px) {
  h5 {
    font-size: 1rem;
  }
}

h6 {
  font-size: calc(0.7272727273rem + 0.3636363636vw);
}
@media (min-width: 1200px) {
  h6 {
    font-size: 1rem;
  }
}

/* purgecss start ignore */
/* purgecss end ignore */